:root {
    --header_color: #1F4173;
}

.notification__container {
    position: absolute;
    width: 100%;
    height: 418px;
    border: 1px solid #E6EAF0;
}

.notification__list-header-container {
    display: flex;
    justify-content: space-between;
}

.notification__list-contant-container {
    display: flex;
    justify-content: space-between;
}

.notification__list {
    border-bottom: 1px solid #E6EAF0;
    padding-top: 10px;
    cursor: pointer;
}

.notification__list-main {
    overflow: scroll;
    height: 308px;
    padding: 0px 20px;
}

.notification__header-container {
    padding: 0px 20px;
    margin: 20px 0px;
}

.notification__header {
    font-size: 20px;
    font-weight: 600;
    color: var(--header_color);
}

.notofication__list-header {
    font-size: 14px;
    font-weight: 700;
    color: var(--header_color);
}

.notofication__list-header:hover {
    cursor: pointer;
}

.notofication__list-content,
.notofication__list-time {
    font-size: 12px;
    font-weight: 400;
    color: #8FA0B9;
}

.notification__btn-conatiner {
    cursor: pointer;
    padding: 10px 20px;
    position: absolute;
    bottom: 0;
    background: #fff;
    width: 100%;
    text-align: center;
}

.see_all-btn {
    font-size: 12px;
    font-weight: 600;
    color: #003AD2;
}

.active_notification_dot {
    height: 10px;
    width: 10px;
    margin-left: 10px;
}


.notification__container::before {
    content: "";
    position: absolute;
    width: 22.76px;
    height: 14px;
    transform: rotate(45deg);
    background-color: #fff;
    right: 20px;
    border-left: 2px solid #E6EAF0;
    border-top: 2px solid #E6EAF0;
    top: -13px;
}
.widget_card {
    height: 150px;
    margin: 10px 0px;
    border-radius: 20px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 34px;
}

.widget_card h4 {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 0px;
}

.widget_card h1 {
    font-weight: 600;
    font-size: 42px;
    margin-bottom: 0px;
}

.widget_card h5 {
    font-size: 14px;
    line-height: 14px;
    margin-left: 1rem;
    margin-bottom: -0.5rem;
}
::-webkit-scrollbar {
    width: 4px !important;
    height: 4px !important;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
}

.MuiTableCell-body {
    text-align: center !important;
}

.MuiTableCell-head {
    text-align: center !important;
}


.formBox {
    position: relative;
    margin: auto;
    padding: 30px 34px;
    border-radius: 10px;
    box-shadow: 0px 4px 90px rgb(163 171 185 / 24%);
}


.main-container {
    width: 100%;
    height: 100vh;
    transition: 0.4s linear;
}

.box {
    width: 460px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
    box-sizing: border-box;
    box-shadow: 0px 4px 90px rgb(163 171 185 / 24%);
    border-radius: 10px;
}

.box_img {
    width: 31px;
    margin: 0px auto;
}

.box img {
    width: 40px;
}

.box p {
    margin-bottom: 0;
}

.box p:nth-child(even) {
    margin-top: 0;
}

.box a {
    color: #9a9d9e;
    font-size: 14px;
    text-decoration: none;
}

.box .input-box {
    position: relative;
}

.box .input-box input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    margin-bottom: 30px;
    color: #fff;
    border: 1px solid #fff;
    border: none;
    border-bottom-style: solid;
    outline: none;
    letter-spacing: 1px;
    background: transparent;
}

.paginationlinkactive {
    background: #000;
    color: #fff;
}


.CustomPhone {
    border: none !important;
    padding: 0px !important;
}

.CustomPhone input.PhoneInputInput {
    height: 48px;
    margin-left: 8px;
    border: 1px solid #E6EAF0 !important;
    padding-left: 25px;
    border-radius: 6px;
}

.CustomPhone .PhoneInputCountry {
    border: 1px solid #E6EAF0 !important;
    border-radius: 6px;
    padding: 0px 20px;
}

.customMobileInput input::placeholder,
.customMobileInput .customCountryCallingCode::placeholder,
.CustomPhone input.PhoneInputInput::placeholder {
    font-size: 14px;
    color: #8E8E8E;
    font-weight: 400;
}

.customMobileInput input:focus-visible,
.customMobileInput .customCountryCallingCode:focus-visible,
.CustomPhone input.PhoneInputInput:focus-visible {
    outline: none !important;
}

.customMobileInput {
    display: flex;
}

.customMobileInput .customCountryCallingCode {
    width: 78px;
    height: 48px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #E6EAF0;
    margin-right: 20px;
}



.box .input-box label {
    position: absolute;
    color: #fff;
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 16px;
    pointer-events: none;
    transition: .5s;
}

.box .input-box input:focus~label,
.box .input-box input:valid~label {
    top: -18px;
    left: 0;
    color: #03a9f4;
    font-size: 12px;
}

.box input[type=submit] {
    background: transparent;
    border: none;
    outline: none;
    background: #03a9f4;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

#logged-in {
    width: 100%;
    height: auto;
    text-align: center;
    margin: auto;
    position: absolute;
    top: 50px;
    display: none;
}

.login-true {
    opacity: 0;
}



/*===============================================
========== CUSTOM BOOTSTRAP CSS START ===========
===============================================*/
.font-6 {
    font-size: 6px !important;
}

.font-7 {
    font-size: 7px !important;
}

.font-8 {
    font-size: 8px !important;
}

.font-9 {
    font-size: 9px !important;
}

.font-10 {
    font-size: 10px !important;
}

.font-11 {
    font-size: 11px !important;
}

.font-12 {
    font-size: 12px !important;
}

.font-13 {
    font-size: 13px !important;
}

.font-14 {
    font-size: 14px !important;
}

.font-15 {
    font-size: 15px !important;
}

.font-16 {
    font-size: 16px !important;
}

.font-17 {
    font-size: 17px !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-19 {
    font-size: 19px !important;
}

.font-20 {
    font-size: 20px !important;
}

.font-21 {
    font-size: 21px !important;
}

.font-22 {
    font-size: 22px !important;
}

.font-23 {
    font-size: 23px !important;
}

.font-24 {
    font-size: 24px !important;
}

.font-25 {
    font-size: 25px !important;
}

.font-26 {
    font-size: 26px !important;
}

.font-27 {
    font-size: 27px !important;
}

.font-28 {
    font-size: 28px !important;
}

.font-29 {
    font-size: 29px !important;
}

.font-30 {
    font-size: 30px !important;
}

.font-31 {
    font-size: 31px !important;
}

.font-32 {
    font-size: 32px !important;
}

.font-33 {
    font-size: 33px !important;
}

.font-34 {
    font-size: 34px !important;
}

.font-35 {
    font-size: 35px !important;
}

.font-36 {
    font-size: 36px !important;
}

.font-37 {
    font-size: 37px !important;
}

.font-38 {
    font-size: 38px !important;
}

.font-39 {
    font-size: 39px !important;
}

.font-40 {
    font-size: 40px !important;
}

.font-41 {
    font-size: 41px !important;
}

.font-42 {
    font-size: 42px !important;
}

.font-43 {
    font-size: 43px !important;
}

.font-44 {
    font-size: 44px !important;
}

.font-45 {
    font-size: 45px !important;
}

.font-46 {
    font-size: 46px !important;
}

.font-47 {
    font-size: 47px !important;
}

.font-48 {
    font-size: 48px !important;
}

.font-49 {
    font-size: 49px !important;
}

.font-50 {
    font-size: 50px !important;
}

.font-64 {
    font-size: 64px !important;
}

.focus-remove:focus {
    outline: unset;
}

.placeholder-color {
    color: #8E8E8E;
}

.min-width-custom {
    min-width: 100% !important;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.primary-color {
    color: #1F4173 !important;
}

.text-cadet-grey-color {
    color: #8FA0B9 !important;
}

.text-sapphire-glitter-color {
    color: #003AD2 !important;
}

.text-rock-blue-color {
    color: #8FA0B9 !important;
}

.text-bahama-blue-color {
    color: #1F4173 !important;
}

.border-radius-8 {
    border-radius: 8px;
}

/*===============================================
========== CUSTOM BOOTSTRAP CSS END =============
===============================================*/

/*===============================================
======== Custom Pagination CSS Start ==========*/
.custom_pagination ul {
    justify-content: end;
}

.custom_pagination ul li:first-child {
    display: none;
}

.custom_pagination ul li:last-child {
    display: none;
}


.custom_pagination ul li button.Mui-selected {
    background-color: #003AD2 !important;
    color: #fff;
    font-size: 14px;
    font-weight: 700;


}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root {
    min-width: 25px !important;
    height: 25px !important;
    margin: 0 5px !important;
}

.custom_pagination ul li button {
    background: #F5F7FB !important;
    font-size: 14px;
    font-weight: 700;

}

.custom_pagination ul li:nth-last-child(-n + 2) button {
    margin-right: "0px !important";
}


/*======== Custom Pagination CSS END ============
===============================================*/

/*===============================================
===== Custom Sweet Alert Design CSS Start =====*/
.swal-overlay {
    background: rgba(0, 0, 0, 0.5);
}

.custom_delete_sweet_alert {
    border-radius: 14px;
    width: 588px;
}

.custom_delete_sweet_alert .swal-content {
    padding: 0 60px;
    margin-top: 40px;
}

.custom_delete_sweet_alert .swal-content p {
    font-weight: 400;
    font-size: 27px;
}

.custom_delete_sweet_alert .swal-content p span {
    font-weight: 500;
}

.custom_delete_sweet_alert .swal-footer {
    text-align: center;
    margin: 40px 0px;
}

.custom_delete_sweet_alert .swal-footer .swal-button--confirm {
    background: #003AD2;
    border-radius: 8px;
    font-weight: 700;
    font-size: 22px;
    padding: 8px 40px;
    min-width: 210px;
}

.custom_delete_sweet_alert .swal-footer .swal-button--cancel {
    border-radius: 8px;
    font-weight: 700;
    font-size: 22px;
    padding: 8px 40px;
    color: #1F4173;
    background-color: transparent;
    min-width: 210px;
}

.custom_delete_sweet_alert .swal-footer button:focus {
    box-shadow: unset;
}

.swal-overlay--show-modal {
    opacity: 1;
}

/*===== Custom Sweet Alert Design CSS END =======
===============================================*/

/*===============================================
==== Custom Notyf Toaster Design CSS Start ====*/
.notyf {
    height: auto !important;
    padding: 0px !important;
    /* position: absolute !important; */
    display: unset !important;
    left: 367px !important;
    width: 100% !important;
}

.notyf .notyf__toast {
    /* max-width: 990px; */
    margin-bottom: 0px;
    overflow: inherit;
    background: #EAFFEA !important;
    margin-top: 15px;
    box-shadow: none;
    padding: 0 35px;
    border-radius: 8px;
}

.notyf__toast {
    max-width: calc(100% - 410px) !important;
}

.notyf .notyf__wrapper {
    padding: 0px;
    width: 100%;
    background: #EAFFEA !important;
}

.notyf .notyf__ripple {
    display: none;
    background: #EAFFEA !important;
    border-radius: 8px;
    width: 1021px;
    height: 89px;
}

.notyf .notyf__message {
    color: #1F7900;
    font-weight: 400;
    font-size: 25px;
    line-height: 77px;
    margin-left: 20px;
}

.notyf__icon::before {
    content: '';
    position: absolute;
    background: url('./assets/images/Check.png');
    z-index: 1024;
    background-size: contain;
    background-repeat: no-repeat;
    top: 50%;
    left: 50%;
    right: 0;
    bottom: 0;
    width: 43.57px;
    height: 43.57px;
    transform: translate(-50%, -50%);
}

.notyf__icon--success {
    display: none !important;
}

/*===== Custom Notyf Toaster Design CSS End =====
===============================================*/

/*===============================================
===== Custom Radio Button Design CSS Start =====*/
.custom-radio-btn [type="radio"]:checked,
.custom-radio-btn [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.custom-radio-btn [type="radio"]:checked+label,
.custom-radio-btn [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}

.custom-radio-btn [type="radio"]:checked+label:before {
    border: 2px solid #F5B926;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #fff;
}

.custom-radio-btn [type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #C9C8C8;
    border-radius: 100%;
    background: #fff;
}

.custom-radio-btn [type="radio"]:checked+label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #F5B926;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.custom-radio-btn [type="radio"]:not(:checked)+label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #C9C8C8;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.custom-radio-btn [type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.custom-radio-btn {
    width: 120px;
}


input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    background: url("./assets/images/calenderIcon.png") no-repeat;
}

/*===== Custom Radio Button Design CSS END ======
===============================================*/


/*===============================================
============ Login Page CSS Start =============*/
.login_form .form-label {
    font-weight: 500;
    font-size: 18px;
}

/*========= Login Page CSS END =================
===============================================*/

/*===============================================
======= Custom Filter Design CSS Start ========*/
.custom_filter .MuiFormLabel-colorPrimary {
    line-height: 1;
    font-size: 15px;
    color: #1F4173;
    opacity: 0.8;
    top: -5px;
}

.custom_filter .MuiInput-underline {
    margin-top: 6px;
}

.custom_filter .MuiInput-underline:before {
    border-bottom: 1px solid #E6EAF0 !important;
}

.custom_filter .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
    text-align: left;
}

/*======= Custom Filter Design CSS END =========
===============================================*/

/*===============================================
======= Custom Dailog Design CSS Start ========*/
.OTPVerificationDialog .MuiPaper-root {
    box-shadow: 14px 14px 74px rgba(0, 0, 0, 0.1);
    border-radius: 23px;
    padding: 50px 40px 50px 40px;
}

/*======= Custom Dailog Design CSS END ==========
===============================================*/

/*===============================================
==== Custom Profile Input Design CSS Start ====*/
.customProfileInput .MuiInputBase-colorPrimary {
    border-radius: 5px;
}

.customProfileInput .MuiInputBase-colorPrimary.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: #E6EAF0;
}

.customProfileInput .MuiOutlinedInput-notchedOutline {
    padding: 0 20px;
}

.customProfileInput .MuiInputLabel-formControl {
    left: 25px;
    font-weight: 400;
    font-size: 16px;
    color: #000000 !important;
}

.customProfileInput .css-1in441m>span {
    padding-left: 25px;
}

.customProfileInput .MuiOutlinedInput-input {
    height: 48px;
    padding: 0 20px;
    font-weight: 400;
    font-size: 14px;
    color: #8E8E8E;
}


/*== Custom Profile Input Design CSS END ========
===============================================*/

button:disabled,
button[disabled] {
    background: #8E8E8E !important;
}


.input-field {
    border: 1px solid #E6EAF0 !important;
    height: 48px;
    padding: 0rem 1rem;
    border-radius: 5px;
}

.apexcharts-legend-marker {
    height: 8px !important;
    width: 8px !important;
}

.apexcharts-legend-marker::before {
    content: '';
    background: #fff;
    position: absolute;
    height: 4px;
    width: 4px;
    border-radius: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.custom_search_form input.search_input::placeholder {
    color: #1F4173;
    opacity: 0.8;
    font-size: 13px;
    /* Firefox */
}

.custom_search_form input.search_input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #1F4173;
    opacity: 0.8;
    font-size: 13px;
}

.custom_search_form input.search_input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #1F4173;
    opacity: 0.8;
    font-size: 13px;
}

.MuiBreadcrumbs-li,
.css-1wuw8dw-MuiBreadcrumbs-separator,
.MuiBreadcrumbs-separator {
    color: #8FA0B9;
    font-size: 22px;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
.MuiPaper-elevation8 {
    box-shadow: none !important;
    border: 1px solid #E6EAF0;
    border-radius: 0 !important;
    overflow: visible !important;
    margin-top: 15px;
    margin-left: 2px;
    border-radius: 1px;
}

.MuiPaper-elevation8 ul {
    padding-top: 8px;
    padding-bottom: 8px;
}

.MuiListItem-button:hover {
    background-color: transparent;
}

.MuiPaper-elevation8 ul::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 9px;
    transform: rotate(45deg);
    background-color: #fff;
    right: 8px;
    border-left: 1px solid #E6EAF0;
    border-top: 1px solid #E6EAF0;
    top: -6px;
}
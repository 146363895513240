 
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.PhoneInputInput {
    border: none !important;
    background: white !important;
    color: rgb(106, 106, 106) !important;
}

.notification_icon {
    position: absolute;
    top: 35px;
    right: 38px;
    z-index: 1024;
}

/* .active_notification_icon {
    height: 24px;
    width: 24px;
} */
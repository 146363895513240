.tableBox .MuiPaper-elevation1 {
    box-shadow: none;
    border-radius: 15px;
}


.tableBox {
    box-shadow: 0px 4px 90px rgba(163, 171, 185, 0.24);
    border-radius: 15px;
    position: relative;
}

.tableBox .MuiPaper-elevation1 {
    box-shadow: none;
}

.tableBox table tbody tr th {
    padding: 20px 0;
}

.tableBox table tbody tr:last-child th {
    border-bottom: 0px;
}



.tableBox tr th:first-child {
    padding-left: 32px;
    text-align: left !important;
}

.tableBox tr th:last-child {
    padding-right: 22px;
}
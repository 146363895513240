.title-logo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.jss30,
.makeStyles-nested-13,
.makeStyles-nested-9,
.makeStyles-nested-16,
.makeStyles-nested-19,
.makeStyles-nested-28,
.makeStyles-nested-32,
.makeStyles-nested-14 {
    padding: 16px !important;
}

.makeStyles-nested-9 {
    padding: 35px !important;
}

.menu-list {
    color: #003ad2;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
    background-color: rgba(0, 0, 0, 0.08);
    border-left: 5px solid #003ad2;
}

.MuiListItem-root {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.innerListItem .MuiListItem-root {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}


.MuiDrawer-paperAnchorDockedLeft {
    box-shadow: 0px 4px 90px rgb(163 171 185 / 24%);
}


.css-1480iag-MuiInputBase-root-MuiInput-root:before {
    border-bottom: none !important;

}

.css-1480iag-MuiInputBase-root-MuiInput-root:after {
    border-bottom: none !important;
}

.MuiDrawer-paper {
    box-shadow: 2px 0px 8px rgb(0 0 0 / 22%) !important;
    border-right: 0px !important;
    width: 325px;
}

.MuiListItem-button:hover {
    background-color: transparent;
}

.MuiListItemIcon-root {
    min-width: 45px !important;
}

.innerListItem {
    position: relative;
}

.innerListItem::before {
    content: '';
    background: #C4C4C4;
    height: 100%;
    width: 1px;
    position: absolute;
    top: 0px;
    left: 63px;
    bottom: 0;
    border-radius: 10px;
}

.innerListItem .MuiCollapse-wrapperInner a {
    position: relative;
}

.innerListItem .MuiCollapse-wrapperInner a::before {
    bottom: 50%;
    top: 50%;
    content: "";
    height: 1px;
    left: 64px;
    position: absolute;
    width: 24px;
    z-index: 1;
    background: #C4C4C4;
    border-radius: 10px;
}

.innerListItem .listItem-title>span {
    font-size: 12px;
    color: #7D7A7A;
}

.MuiListItem-gutters {
    padding-left: 56px !important;
    padding-right: 20px !important;
}

ul:has(div > div > li > div > div > a.active) {
    background: #F5F7FB;
}

.active ::before{
    /* background-size: 10px 20px; */
    background: #003AD2;
    position: absolute;
    left: 0;
    width: 4px; 
    height: 14px;
    margin-top: 4px;
    content:"";
}

/* div:has(li > div > div > a.active) .MuiListItem-button .collapseTitle>span {
    color: #003AD2;
}

div:has(li > div > div > a.active) .MuiListItem-button .collapseIcon svg {
    stroke: #003AD2;
    fill: #003AD2;
    stroke-width: 0.3;
}
 */


.active>div>div:nth-child(2)>span {
    color: #1F4173 !important;
}

li:has(div > div > a.active) .active>div>div:nth-child(2)>span {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: #1F4173 !important;
}

.active>div>div:nth-child(1)>svg {
    stroke: #003AD2;
    fill: #003AD2;
    stroke-width: 0.3;
}

.listItem-title>span,
.collapseTitle>span,
.logout-text>span {
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #8FA0B9;
}

.sidebar .MuiList-padding {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px;
}

.sidebar .MuiListItem-button:hover {
    background-color: transparent !important;
}